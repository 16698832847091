<template>
	<div class="mainte-area d-flex align-center">
		<v-alert class="mainte-box pa-10" outlined color="red" icon="mdi-wrench-clock">
		<div class="text-h5">メンテナンス中</div>
		<div class="my-5 text-h6" v-if="mainteInfo.message">{{mainteInfo.message}}</div>	
		<div v-if="mainteInfo.mainte_start_date" class="d-flex d-inline-flex">
			<div>メンテナンス予定時間：</div>
			<div v-if="mainteInfo.mainte_start_date">{{mainteInfo.mainte_start_date}}</div>
			<div>～</div>
			<div v-if="mainteInfo.mainte_end_date">{{mainteInfo.mainte_end_date}}</div>
		</div>
		</v-alert>
	</div>
</template>
<style scoped>
	.mainte-area{
		width: 80%;
		height:80%;
		position: relative;
	}
	.mainte-box{
		position:absolute;
		top:50%;
		left:50%;
	}
</style>
<script>
export default {
	data() {
		return {
			mainteInfo:[]
		}
	},
	mounted(){
		this.getSystemInfo();
	},
	methods:{
		async getSystemInfo(){
			const res = await this.axios.post('/member/kado-check');
			if(res.data.resultData.SystemInfo.system_status==0){
				//メンテナンス中ではない場合はログイン画面に飛ばす
				this.$router.push('/login');
				return ;
			}
			this.mainteInfo = res.data.resultData.SystemInfo;
		}
	}

	
}
</script>